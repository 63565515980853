body {
  background-color: #f5f7f8;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7f8;
  padding-bottom: 0px;
  max-width: 768px;
  margin: auto;
  position: relative;
}

a {
  text-decoration: none;
}

a:active {
  opacity: 0.5;
}

button:focus {
  outline:0;
}

button:active {
  opacity: 0.5;
}

.animated-wrapper-rule {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  z-index: 99999999;
}

.category-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 321px) {
  body {
    overflow-x: hidden;
  }

  .dapp-category {
    width: 136px;
    min-width: 0%;
  }

}